{
  "name": "eventee-admin",
  "version": "2.5.3",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --host=0.0.0.0 --disable-host-check",
    "start-local": "ng serve --host=192.168.0.24",
    "watch": "ng build --watch",
    "build": "ng build",
    "build-staging": "ng build --configuration=staging",
    "build-production": "ng build --configuration=production",
    "translations": "node scripts/get-locale.js && node scripts/hash-translations.js",
    "add-string": "node scripts/add-locale.js && npm run translations",
    "deploy-staging": "node scripts/deploy.js staging",
    "deploy-production": "node scripts/deploy.js production",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e"
  },
  "private": true,
  "akitaCli": {
    "customFolderName": "true",
    "template": "angular",
    "basePath": "./src/app/"
  },
  "dependencies": {
    "@angular/animations": "^18.2.5",
    "@angular/cdk": "^18.2.5",
    "@angular/common": "^18.2.5",
    "@angular/compiler": "^18.2.5",
    "@angular/core": "^18.2.5",
    "@angular/forms": "^18.2.5",
    "@angular/platform-browser": "^18.2.5",
    "@angular/platform-browser-dynamic": "^18.2.5",
    "@angular/router": "^18.2.5",
    "@auth0/angular-jwt": "^5.1.2",
    "@bugsnag/js": "^8.0.0",
    "@bugsnag/plugin-angular": "^8.0.0",
	"@ctrl/ngx-emoji-mart": "^9.2.0",
    "@datorama/akita": "^8.0.1",
    "@growthbook/growthbook": "^1.2.0",
    "@iplab/ngx-color-picker": "^18.0.1",
    "@messageformat/core": "^3.1.0",
    "@ng-select/ng-select": "^13.8.1",
    "@ngneat/content-loader": "^7.0.0",
    "@ngx-loading-bar/core": "^6.0.2",
    "@ngx-loading-bar/http-client": "^6.0.2",
    "@ngx-loading-bar/router": "^6.0.2",
    "@ngx-translate/core": "^14.0.0",
    "@ngx-translate/http-loader": "^7.0.0",
    "@paddle/paddle-js": "^0.3.1",
    "animate.css": "^4.1.1",
    "body-parser": "^1.20.2",
    "bootstrap": "^4.6.2",
    "chart.js": "^4.2.1",
    "country-codes-list": "1.6.8",
    "country-flag-icons": "^1.5.5",
    "cropperjs": "^1.6.2",
    "css-element-queries": "^1.2.3",
    "d3": "^7.8.2",
    "gleap": "^13.9.0",
    "hammerjs": "^2.0.8",
    "hc-sticky": "^2.2.7",
    "js-cookie": "^3.0.1",
    "lodash": "^4.17.21",
    "messageformat": "^2.3.0",
    "moment": "^2.30.1",
    "moment-duration-format": "^2.3.2",
    "moment-timezone": "^0.5.45",
    "ng-circle-progress": "^1.7.1",
    "ng-recaptcha-2": "^14.0.0",
    "ng2-charts": "^4.1.1",
    "ngx-bootstrap": "^18.0.2",
    "ngx-clipboard": "^15.1.0",
    "ngx-color-picker": "^17.0.0",
    "ngx-mask": "^16.4.2",
    "ngx-moment": "^6.0.2",
    "ngx-quill": "^26.0.8",
    "ngx-scrollbar": "^11.0.0",
    "ngx-toastr": "^16.0.2",
    "ngx-translate-messageformat-compiler": "^6.2.0",
    "ngx-ui-switch": "^15.0.0",
    "ngx-validators": "^6.0.1",
    "product-fruits": "^1.0.25",
    "qr-code-styling": "1.6.0-rc.1",
    "quantize": "^1.0.2",
    "quill": "^2.0.2",
    "rxjs": "~7.8.0",
    "screenfull": "^6.0.2",
    "smoothscroll-polyfill": "^0.4.4",
    "socket.io-client": "^4.6.0",
    "tslib": "^2.3.0",
    "uuid": "^11.1.0",
    "web-animations-js": "2.3.2",
    "xlsx": "^0.18.5",
    "zone.js": "^0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.5",
    "@angular/cli": "^18.2.5",
    "@angular/compiler-cli": "^18.2.5",
    "@faker-js/faker": "^8.0.2",
    "@lokalise/node-api": "^8.3.0",
    "@types/d3": "^7.4.0",
    "@types/google.maps": "^3.58.1",
    "@types/jasmine": "^4.3.0",
    "@types/lodash": "^4.14.191",
    "adm-zip": "^0.5.10",
    "download-file": "^0.1.5",
    "jasmine-core": "^4.5.0",
    "karma": "^6.4.0",
    "karma-chrome-launcher": "^3.1.0",
    "karma-coverage": "^2.2.0",
    "karma-coverage-istanbul-reporter": "^3.0.3",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.0.0",
    "md5": "^2.3.0",
    "node-ssh": "13.2.0",
    "readline": "^1.3.0",
    "rimraf": "^4.4.0",
    "typescript": "^5.5.4"
  }
}
