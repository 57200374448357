import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {AuthService} from '../../store/auth';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {GlobalService} from '../services/global.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import moment from 'moment-timezone';
import {Router} from '@angular/router';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

	private authService: AuthService;
	private globalService: GlobalService;
	private router: Router;
	private isRefreshing = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	private eventeeCdnUrlPrefix = "https://files.eventee";
	private googlePlacesApiPrex = "places.googleapis.com";
	constructor(private injector: Injector) {
	}

	addHeaders(request) {
		const authHeader = localStorage.getItem('token');
		const language = localStorage.getItem('lang') || 'en';
		let headers: any = {
			'Accept-Language': language
		};

		if (authHeader) {
			headers = {
				...headers,
				Authorization: 'Bearer ' + authHeader
			};
		}
		return request.clone({
			setHeaders: headers
		});
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
		this.authService = this.injector.get(AuthService);
		this.globalService = this.injector.get(GlobalService);
		this.router = this.injector.get(Router);

		if (!request.url.includes(this.eventeeCdnUrlPrefix) && !request.url.includes(this.googlePlacesApiPrex)) {
			request = this.addHeaders(request);
		}


		const helper = new JwtHelperService();
		const token = localStorage.getItem('token');

		if (!token) {
			return next.handle(request);
		}

		const isTokenAboutToExpire = moment(helper.getTokenExpirationDate(token)).subtract(30, 'seconds').isBefore(moment());

		// Refresh token before its about to expire (30secs before)
		if (!request.url.includes('/v1/token') && !request.url.includes('.json') && isTokenAboutToExpire) {
			return this.handleTokenRefresh(request, next);
		}

		return next.handle(request).pipe(
			catchError((error) => {
				const removeTokenErrors = ['token_blacklisted', 'token_invalid', 'token_not_provided'];
				if (error instanceof HttpErrorResponse) {
					if ((error.status === 403 || error.status === 401) && removeTokenErrors.includes(error.error?.error)) {
						this.authService.removeToken();
						this.router.navigate(['auth', 'login']);
					}
				}

				return throwError(error);
			})
		);
	}

	private handleTokenRefresh(request: HttpRequest<any>, next: HttpHandler) {
		// If token is not refreshing then:
		// - set state to refreshing
		// - reset observable with refreshToken (refreshTokenSubject)
		// - start refreshing token
		// - if token refresh is successful then send observable with refresh token subject
		// - if something went wrong during refreshing, check if the response is 403 or 401 meaning that you no longer
		// have access and remove the token from storage
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this.refreshTokenSubject.next(null);

			return this.authService.refresh().pipe(
				switchMap((token: any) => {
					this.isRefreshing = false;
					this.refreshTokenSubject.next(token);
					return next.handle(this.addHeaders(request));
				}),
				catchError(error => {
					if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403)) {
						this.authService.removeToken();
					}

					return throwError(error);
				}));
			// Token is already refreshing, so just subscribe to the tokenRefreshSubject and wait for new token to be delivered
		} else {
			return this.refreshTokenSubject.pipe(
				filter(token => token != null),
				take(1),
				switchMap(() => {
					return next.handle(this.addHeaders(request));
				}),
			);
		}
	}
}
